import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { AiOutlineInbox, AiOutlineAppstoreAdd, AiOutlineRotateRight, 
  AiOutlineRotateLeft, AiOutlineCloseSquare, AiOutlineMinusCircle, 
  AiOutlineHdd, AiOutlineBarChart, AiOutlineFileDone } from "react-icons/ai";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {  MenuProps,Menu, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import { User } from '../models';
import { UserRole } from '../enum';
import { UserService } from '../services';
import { useMsal } from "@azure/msal-react";
import { apiRequest } from "../configurations/authConfig";
import './LeftBar.css';

interface INavMenuProps { }
interface INavMenuState {
  collapsed: boolean;
}

interface MenuItem
{
    label:string
    key:string
    icon: JSX.Element
    children:any[]
}

export const LeftBar: FunctionComponent<INavMenuProps> = () => {
  const userStore = secureLocalStorage.getItem('user')
  const user:User = userStore ? JSON.parse(userStore.toString()): null
  const [collapsed, setCollapsed] = useState(false);
  const { instance, accounts } = useMsal();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState();
  const [isLoading,setIsloading] = useState(true)
  const [manualLink,setManualLink] = useState("")

  const changePage = (page:string) => {
    secureLocalStorage.setItem("saveStorage","")
    removeStorageLink()
    if(page === '/'){
     navigate('/')
     addNewCartonLink()
      
    }
    else{
    
      navigate('/' + page)
      removeNewCartonLink(page)
    }
    
  }

  
  const saveDraft = (page:string) => {
    secureLocalStorage.setItem('saveStorage',page)
  
    let saveDraftBtn1 = document.getElementById('storage-save-draft1') as HTMLButtonElement
    if(saveDraftBtn1){
      saveDraftBtn1.click()
    }
    else{
      let saveDraftBtn2 = document.getElementById('storage-save-draft2') as HTMLButtonElement
      if(saveDraftBtn2){
        saveDraftBtn2.click()
      }
    }
  
  }


  const removeStorageLink = () => {
    let elements = document.getElementsByClassName("ant-menu-item") as HTMLCollection
   
     setTimeout(() => {
         let length = elements.length
         for(let i =0; i< length ;i++){
           let id = elements[i].getAttribute("data-menu-id")
      
           if(id && id.indexOf("-storage-carton") > -1 && elements[i].classList.contains('ant-menu-item-selected')){
             elements[i].classList.remove('ant-menu-item-selected')
           }
         }
     }, 1000);
 }

 const addNewCartonLink = () => {
  let elements = document.getElementsByClassName("ant-menu-item") as HTMLCollection
 
   setTimeout(() => {
       let length = elements.length
       for(let i =0; i< length ;i++){
         let id = elements[i].getAttribute("data-menu-id")
    
         if(id && id.indexOf("new-carton") > -1 ){
          elements[i].classList.add('ant-menu-item-selected')
          
        }
        else{
          elements[i].classList.remove('ant-menu-item-selected')
        }
       }
   }, 500);
}

const removeNewCartonLink = (page:string) => {
  
  let elements = document.getElementsByClassName("ant-menu-item") as HTMLCollection
 
   setTimeout(() => {
       let length = elements.length
       for(let i =0; i< length ;i++){
         let id = elements[i].getAttribute("data-menu-id")
    
         if(id && id.indexOf("new-carton") > -1 && elements[i].classList.contains('ant-menu-item-selected') && page !== 'new-carton'){
           elements[i].classList.remove('ant-menu-item-selected')
         }
       }
   }, 500);
}


  const onClick: MenuProps['onClick'] = (e) => {
    
    let saveStorage = secureLocalStorage.getItem('saveStorage')
   
    if(saveStorage && e.key !== 'storage-carton' ){
        modal.confirm({
          title: 'Confirm to save draft before leaving this page?',
          icon: <ExclamationCircleOutlined />,
          content: '',
          okText: 'Save Draft',
          cancelText: 'Cancel',
          width: 430,
          onCancel: () => changePage(e.key),
          onOk: () => saveDraft(e.key)
        });
     
    }
    else{
      changePage(e.key)
    }
   
  };

  const onClickIcon = () => {
  
    let saveStorage = secureLocalStorage.getItem('saveStorage')
   
    if(saveStorage ){
        modal.confirm({
          title: 'Confirm to save draft before leaving this page?',
          icon: <ExclamationCircleOutlined />,
          content: '',
          okText: 'Save Draft',
          cancelText: 'Cancel',
          width: 430,
          onCancel: () => changePage("/"),
          onOk: () => saveDraft("/")
        });
     
    }
    else{
      changePage("/")
    }
   
  };

  let items:any = []

  const documentArchiveItem = {
    icon: <AiOutlineFileDone />,
    key: "doc_archive",
    label: "Document Archive",
    children:[
      { label: "New Carton", key: "new-carton", icon: <AiOutlineInbox /> },
      { label: "Storage Carton", key: "storage-carton", icon: <AiOutlineAppstoreAdd /> },
      { label: "Collect Carton", key: "collect-carton", icon: <AiOutlineRotateRight /> },
      { label: "Return Carton", key: "return-carton", icon: <AiOutlineRotateLeft /> },
      { label: "Remove Carton", key: "remove-carton", icon: <AiOutlineCloseSquare /> },
      { label: "Destruction Carton", key: "destruction-carton", icon: <AiOutlineMinusCircle /> },
      { label: "Request to Change Owner", key: "request-change-owner", icon: <AiOutlineMinusCircle /> }
    ]
  }

  const approvalItem = {
    icon: <AiOutlineFileDone />,
    key: "approvalItem",
    label: "Approval",
    children: [
      { label: "Waiting for Approval", key: "approval", icon: <AiOutlineInbox /> },
      { label: "My Approval Tracking", key: "approve-tracking", icon: <AiOutlineFileDone /> }
    ]
  }

  const myTaskChildren = [
    { label: "Status Tracking", key: "status-tracking", icon: <AiOutlineFileDone /> },
    { label: "My Org. Unit Document", key: "my-document", icon: <AiOutlineMinusCircle /> },
    { label: "Reminder Email", key: "reminder-email", icon: <AiOutlineMinusCircle /> }
  ]

  const myTaskItem = {
    icon: <AiOutlineFileDone />,
    key: "mytask",
    label: "My Task",
    children: myTaskChildren
  }

  
  const systemAdminItem:MenuItem = {
    icon: <AiOutlineFileDone />,
    key: "systemAdmin",
    label: "System Admin",
    children:[]
  }
  const queryDataItem = { label: "Query Data", key: "query-data", icon: <AiOutlineInbox />}
  const destructionCartonItem = { label: "Destruction Carton", key: "officer-destruction-carton", icon: <AiOutlineAppstoreAdd />}
  const onBehalfItem = { label: "On Behalf", key: "onbehalf", icon: <AiOutlineMinusCircle />}

  const masterDataItem:MenuItem = { label: "Master Data", key: "master", icon: <AiOutlineHdd /> ,
      children:[]
  }

  const systemManagementItem = 
    { label: "System Management", key: "system", icon: <AiOutlineHdd /> ,
      children:[
        { label: "Manage User", key: "manage-user" },
        { label: "System Config", key: "system-config" },
        { label: "Mail Message", key: "mail-message" },
        { label: "Terms and Conditions", key: "terms-and-conditions" }
      ]
    }
  

  const reportItem = 
      { label: "Report", key: "sub2", icon: <AiOutlineBarChart /> ,
      children:[
        { label: "All Transaction", key: "report-all-transaction" },
        { label: "Total Carton List", key: "report-total-carton-list" },
        { label: "Destruction Carton List", key: "report-destruction-carton-list" },
        { label: "Carton Quantity by Request Type", key: "report-carton-quantity" },
        { label: "Destruction Carton Quantity", key: "report-destruction-carton-quantity" }
      ]
    }
    
    const getToken = async () => {
      const acc = accounts && accounts.length ? accounts[0] : null;
      apiRequest.scopes[0] = "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
      if (acc) {
          const { accessToken: token } = await instance.acquireTokenSilent({
              account: acc,
              scopes: apiRequest.scopes
          });
          return token;
      }
      return ""
  }

  
    const getManaualLink = async () => {
      let token = await getToken()
        let result = await  new UserService(token).getManualLink()
        
        if(result?.data)
            setManualLink(result.data)
    }
 
  useEffect(() => {
    
    if(!user || !user.permissions){
      navigate('/logout')
    }
    if(user)
    {
     
        documentArchiveMenu();
        queryDataMenu();
        destructionCartonMenu()
        onBehalfMenu()
        masterDataMenu();
        systemMenu()
        myTaskMenu()
        approvalMenu()
        systemAdminMenu()
        reportMenu()
    }
    if(user && !user.manualLink){
        getManaualLink()
    }
    setMenuItems(items);
    setIsloading(false)
    if (windowSize.current[0] < 993) {
      setCollapsed(true);
    }

  }, [])

    const documentArchiveMenu = () => {
        if (user.permissions.documentArchive > 0)
        {
            items.push(documentArchiveItem)
        }
    }

    const queryDataMenu = () => {
        if (user.permissions.queryData > 0)
        {
            systemAdminItem.children.push(queryDataItem)
        }
    }

    const destructionCartonMenu = () => {
        
        if (user.permissions.destructionCarton > 0)
        {
            systemAdminItem.children.push(destructionCartonItem)
        }
    }
    const onBehalfMenu = () => {
        
        if (user.permissions.onBehalf > 0)
        {
            systemAdminItem.children.push(onBehalfItem)
        }
    }
    const systemMenu = () => {
        
        if (user.permissions.system > 0)
        {
            systemAdminItem.children.push(systemManagementItem)
        }
    }
    const myTaskMenu = () => {
        
        if (user.permissions.myTask > 0)
        {
            items.push(myTaskItem)
        }
    }
    const approvalMenu = () => {
        let addApprovalMenu = false
        if (user.approveLevel == 2 && user.roleIds.filter(x => x.roleId == UserRole.NormalUser).length > 0)
        {
            items.push(approvalItem)
            addApprovalMenu = true
        }
        if(user.roleIds.filter(x => x.roleId == UserRole.SuperAdmin).length > 0 && !addApprovalMenu){
          items.push(approvalItem)
        }
    }
    const systemAdminMenu = () => {
        
        if (systemAdminItem.children.length > 0)
        {
            items.push(systemAdminItem)
        }
    }
    const reportMenu = () => {
        
        if (user.permissions.report > 0)
        {
            items.push(reportItem)
        }
    }

    const masterDataMenu = () => {
        if (user.permissions.warehouse == 1)
        {
            masterDataItem.children.push( { label: "Manage External Warehouse", key: "manage-external-warehouse" })
        }
        if (user.permissions.changeOwner == 1)
        {
            masterDataItem.children.push( { label: "Manage Change Owner", key: "manage-change-owner" })
        }
        if (user.permissions.orgUnit == 1)
        {
            masterDataItem.children.push({ label: "Manage Org. Unit", key: "manage-org-unit" })
        }
        if (user.permissions.projectName == 1)
        {
            masterDataItem.children.push({ label: "Manage Project Name", key: "manage-project-name" })
        }
        if(masterDataItem.children.length > 0)
        {
            systemAdminItem.children.push(masterDataItem)
        }
    }
  const toggleCollapsed = () => {

    if (!collapsed) {
      if (windowSize.current[0] >= 993) {
        document.body.setAttribute('data-sidebar-size', 'sm');
      }
      document.body.classList.remove('sidebar-enable');
    } else {
      document.body.setAttribute('data-sidebar-size', 'lg');
      document.body.classList.add('sidebar-enable');
    }


    setCollapsed(!collapsed);
  };

  const login = () => {
    navigate('/login')
  }

  const logout = () => {
    navigate('/logout')
  }

  return (
    <div>
      {!isLoading ? 
      <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* LOGO */}
            <div className="navbar-brand-box">
              <a  className="logo logo-ptt logo-dark" onClick={onClickIcon}>
                <span className="logo-sm">
                  <img src="images/logo-pttep.png" height={22} />
                </span>
                <span className="logo-lg logo-ptt">
                  <img src="images/logo-pttep.png" height={22} />
                </span>
              </a>
              <a  className="logo logo-light logo-ptt" onClick={onClickIcon}>
                <span className="logo-sm">
                  <img src="images/logo-pttep.png" height={22} />
                </span>
                <span className="logo-lg">
                  <img src="images/logo-pttep.png" height={22} />
                </span>
              </a>
            </div>
            <button type="button" onClick={toggleCollapsed} className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn">
              <i className="fa fa-fw fa-bars" />
            </button>

          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block">
            
              <button type="button" className="btn header-item user text-start d-flex align-items-center" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img className="rounded-circle header-profile-user" src={user && user.gender === 'F' ? 'images/user-f.png': 'images/user.png' } alt="Header Avatar" />
                <span className="ms-2 d-none d-sm-block user-item-desc">
                  <span className="user-name">{user ? user.employeeName : ""}</span>
                  <span className="user-sub-title">{user ? user.roleIds.map( x=> { return x.roleName}).join(",") : ""}</span>
                </span>
               
              </button>
            
             
              <div className="dropdown-menu dropdown-menu-end pt-0">
               
                <a className="dropdown-item" href="auth-signout-basic.html" onClick={() => logout()}>
                    <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1" />
                     <span className="align-middle">Logout</span>
                </a>
              </div>
            </div>
            <div className="dropdown d-inline-block">
             
              < a href={user && user.manualLink ? user.manualLink : manualLink} target='_blank'>
                <img src="images/manual.png" className="manual-img"/>
              </a>
              
            </div>
          </div>
        </div>
      </header>
      <div className="vertical-menu">
        {/* LOGO */}
        <div className="navbar-brand-box">
          <a className="logo logo-dark logo-ptt" onClick={onClickIcon}>
            <span className="logo-sm">
              <img src="images/logo-pttep.png" height={22} />
            </span>
            <span className="logo-lg">
              <img src="images/logo-pttep.png" height={62} />
            </span>
          </a>
          <a className="logo logo-light logo-ptt" onClick={onClickIcon}>
            <span className="logo-lg">
              <img src="images/logo-pttep.png" height={22} />
            </span>
            <span className="logo-sm">
              <img src="images/logo-pttep.png" height={62} />
            </span>
          </a>
        </div>

        <button type="button" onClick={toggleCollapsed} className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn">
          <i className="fa fa-fw fa-bars" />
        </button>
        <div  className="sidebar-menu-scroll">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" >
              <li className="menu-title" data-key="t-dashboards">
               <span>Menu v1.2.3</span>
             
              </li>
              <li>
              </li>
            </ul>
              
                <Menu
                  onClick={onClick}
                  defaultSelectedKeys={['new-carton-list']}
                  inlineCollapsed={collapsed}
                  mode="inline"
                  items={menuItems}
                />
          </div>
        </div>
      </div>
      </>
      :<></>
      }
      {contextHolder}
    </div>
  );
}
